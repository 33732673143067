'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import { ChevronLeft, SaveAltSharp } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { format } from 'date-fns';

import {
  ReportCampaignList,
  useBreadcrumbs,
  useDownloadReportsCampaign,
  useGetAdvertiser,
  useGetCampaign,
  useGetReportsCampaign,
} from '~/entities';
import {
  ContentTitle,
  Pagination,
  StyledContainer,
  StyledTableContainer,
} from '~/shared';
import { ReportsCampaignDto } from '~/types';

export type RelatoriosCampanhaProps = {
  advertiserId: string;
  campaignId: string;
};

const REPORTS_CAMPAIGNS_PAGE_SIZE = 15;

export function RelatoriosCampanhaView({
  advertiserId,
  campaignId,
}: RelatoriosCampanhaProps) {
  const breadcrumbs = useBreadcrumbs();
  const [reportsFilter, setReportsFilter] = useState<ReportsCampaignDto>({
    idCampaign: campaignId,
    count: REPORTS_CAMPAIGNS_PAGE_SIZE,
    page: 1,
  });

  const getAdvertiserQuery = useGetAdvertiser(advertiserId);
  const getCampaignQuery = useGetCampaign(campaignId);
  const getReportsCampaignQuery = useGetReportsCampaign(reportsFilter);
  const { isLoading, isFetching, data } = getReportsCampaignQuery;

  const total = data?.total ?? 0;
  const totalPages = Math.ceil(total / REPORTS_CAMPAIGNS_PAGE_SIZE);

  const advertiserTitle = getAdvertiserQuery.data?.name ?? 'Carregando';
  const campaignTitle = getCampaignQuery.data?.name ?? 'Carregando';

  useEffect(() => {
    breadcrumbs.setPaths([
      { label: 'Relatórios', path: '/relatorios' },
      { label: advertiserTitle, path: `/relatorios/${advertiserId}` },
      {
        label: campaignTitle ?? 'Carregando',
        path: `/relatorios/${advertiserId}/${campaignId}`,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advertiserTitle, campaignTitle]);

  const downloadReportsCampaignMutation = useDownloadReportsCampaign(res => {
    const nowFormatted = format(new Date(), 'dd-MM-yyyy HH-mm');
    const filename = `Relatório Campanha - ${getCampaignQuery.data?.name ?? 'Sem Nome'} - ${nowFormatted}.xlsx`;
    const blob = new Blob([res.data], { type: res.headers['content-type'] });
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = filename;
    a.click();
  });

  function handleExportExcel() {
    downloadReportsCampaignMutation.mutate(reportsFilter);
  }

  function nextPageHandler() {
    if (reportsFilter.page < totalPages) {
      setReportsFilter(state => ({
        ...state,
        page: state.page + 1,
      }));
    }
  }

  function prevPageHandler() {
    if (reportsFilter.page > 1) {
      setReportsFilter(state => ({
        ...state,
        page: state.page - 1,
      }));
    }
  }

  return (
    <>
      <ContentTitle title="Relatórios" isFetching={isLoading || isFetching} />

      <StyledContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link href={`/relatorios/${advertiserId}`} passHref>
            <Button variant="text" color="primary">
              <ChevronLeft />
              Voltar
            </Button>
          </Link>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ textTransform: 'none' }}
            startIcon={
              downloadReportsCampaignMutation.isPending ? (
                <CircularProgress size={15} />
              ) : (
                <SaveAltSharp />
              )
            }
            onClick={handleExportExcel}
            disabled={downloadReportsCampaignMutation.isPending}
          >
            Baixar
          </Button>
        </Box>

        <Box sx={{ my: 2 }}>
          <Typography
            variant="h5"
            sx={theme => ({
              fontSize: theme.typography.pxToRem(18),
              fontWeight: 500,
              color: '#BDBDBD',
            })}
          >
            {getAdvertiserQuery.data?.name ?? 'Carregando...'}
          </Typography>
          <Typography
            variant="h4"
            sx={theme => ({
              fontSize: theme.typography.pxToRem(26),
              fontWeight: 500,
            })}
          >
            {getCampaignQuery.data?.name ?? 'Carregando...'}
          </Typography>
        </Box>

        <StyledTableContainer>
          <ReportCampaignList
            advertiserId={advertiserId}
            campaignId={campaignId}
            // campaignName={getCampaignQuery.data?.name}
            getReportsCampaignQuery={getReportsCampaignQuery}
          />
        </StyledTableContainer>
        <Pagination
          disabled={totalPages === 1 || isLoading || total === 0}
          page={1}
          count={1}
          nextPage={nextPageHandler}
          prevPage={prevPageHandler}
          onChange={(_, newPage) =>
            setReportsFilter(state => ({
              ...state,
              page: newPage,
            }))
          }
        />
      </StyledContainer>
    </>
  );
}
