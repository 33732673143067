'use client';

import { useEffect, useState } from 'react';
import Link from 'next/link';
import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import {
  ReportCampaignsList,
  useBreadcrumbs,
  useGetAdvertiser,
  useGetReportsCampaigns,
} from '~/entities';
import {
  ContentTitle,
  Pagination,
  StyledContainer,
  StyledTableContainer,
} from '~/shared';
import { ReportsCampaignsDto } from '~/types';

export type RelatoriosCampanhasProps = {
  advertiserId: string;
};

const REPORTS_CAMPAIGNS_PAGE_SIZE = 15;

export function RelatoriosCampanhasView({
  advertiserId,
}: RelatoriosCampanhasProps) {
  const breadcrumbs = useBreadcrumbs();
  const [reportsFilter, setReportsFilter] = useState<ReportsCampaignsDto>({
    idAdvertiser: advertiserId,
    count: REPORTS_CAMPAIGNS_PAGE_SIZE,
    page: 1,
  });

  const getAdvertiserQuery = useGetAdvertiser(advertiserId);
  const getReportsCampaignsQuery = useGetReportsCampaigns(reportsFilter);
  const { isLoading, isFetching, data } = getReportsCampaignsQuery;

  // const downloadReportsCampaignsMutation = useDownloadReportsCampaigns(res => {
  //   const nowFormatted = format(new Date(), 'dd-MM-yyyy HH-mm');
  //   const filename = `Relatório Anunciante - ${getAdvertiserQuery.data?.name ?? 'Sem Nome'} - ${nowFormatted}.xlsx`;
  //   const blob = new Blob([res.data], { type: res.headers['content-type'] });
  //   const downloadUrl = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = downloadUrl;
  //   a.download = filename;
  //   a.click();
  // });

  const total = data?.total ?? 0;
  const totalPages = Math.ceil(total / REPORTS_CAMPAIGNS_PAGE_SIZE);

  const contentTitle = getAdvertiserQuery.data?.name ?? 'Carregando';

  useEffect(() => {
    breadcrumbs.setPaths([
      { label: 'Relatórios', path: '/relatorios' },
      { label: contentTitle, path: `/relatorios/${advertiserId}` },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentTitle]);

  function nextPageHandler() {
    if (reportsFilter.page < totalPages) {
      setReportsFilter(state => ({
        ...state,
        page: state.page + 1,
      }));
    }
  }

  function prevPageHandler() {
    if (reportsFilter.page > 1) {
      setReportsFilter(state => ({
        ...state,
        page: state.page - 1,
      }));
    }
  }

  // function handleExportExcel() {
  //   downloadReportsCampaignsMutation.mutate(reportsFilter);
  // }

  return (
    <>
      <ContentTitle title="Relatórios" isFetching={isLoading || isFetching} />

      <StyledContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link href="/relatorios" passHref>
            <Button variant="text" color="primary">
              <ChevronLeft />
              Voltar
            </Button>
          </Link>
          {/* <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ textTransform: 'none' }}
            startIcon={
              downloadReportsCampaignsMutation.isPending ? (
                <CircularProgress size={15} />
              ) : (
                <SaveAltSharp />
              )
            }
            disabled={downloadReportsCampaignsMutation.isPending}
            onClick={handleExportExcel}
          >
            Baixar
          </Button> */}
        </Box>

        <Box sx={{ my: 2 }}>
          <Typography
            variant="h4"
            sx={theme => ({
              fontSize: theme.typography.pxToRem(26),
              fontWeight: 500,
            })}
          >
            {getAdvertiserQuery.data?.name ?? 'Carregando...'}
          </Typography>
        </Box>

        <StyledTableContainer>
          <ReportCampaignsList
            advertiserId={advertiserId}
            getReportsCampaignsQuery={getReportsCampaignsQuery}
          />
        </StyledTableContainer>
        <Pagination
          disabled={totalPages === 1 || isLoading || total === 0}
          page={1}
          count={1}
          nextPage={nextPageHandler}
          prevPage={prevPageHandler}
          onChange={(_, newPage) =>
            setReportsFilter(state => ({
              ...state,
              page: newPage,
            }))
          }
        />
      </StyledContainer>
    </>
  );
}
