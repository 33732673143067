'use client';

import isPropValid from '@emotion/is-prop-valid';
import {
  Box,
  type BoxProps,
  ListItemButton,
  ListItemText,
  type ListItemTextProps,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

interface StyledListItemTextProps extends ListItemTextProps {
  open?: boolean;
}

interface StyledMenuButtonBoxProps extends BoxProps {
  open?: boolean;
}

interface StyledBoxContentProps extends BoxProps {
  open?: boolean;
}

export const StyledMainDrawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    overflowX: 'hidden',
    scrollX: 'hidden',
    boxSizing: 'border-box',
    position: 'relative',
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
    display: 'flex',
    alignContent: 'space-between',
    width: theme.uiSizes.drawerWidth,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    transition: theme.transitions.create(['width', 'padding', 'left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    ...(!open && {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: theme.uiSizes.drawerWidthClosed,
    }),
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: theme.uiSizes.headerHeight,
      left: 0,
      border: 'none',
      height: `calc(100% - ${theme.uiSizes.headerHeight}px)`,
      width: '100%',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      ...(!open && {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: '100%',
        left: '-100%',
      }),
    },
  },
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(1),
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.text.secondary,
    transition: theme.transitions.create(['background-color', 'color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    '&.active': {
      pointerEvents: 'none',
    },
    '&.active, &:hover': {
      backgroundColor: theme.palette.action.hover,
      color: theme.palette.primary.light,
    },
  };
});

export const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<StyledListItemTextProps>(({ theme, open }) => {
  return {
    padding: 0,
    display: 'inline-block',
    flexGrow: 0,
    span: {
      fontSize: theme.typography.pxToRem(14),
      display: 'inline-block',
      opacity: open ? '1' : '0',
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
      }),
    },
  };
});

export const StyledMenuButtonBox = styled(Box, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<StyledMenuButtonBoxProps>(({ theme, open }) => {
  return {
    padding: 0,
    width: '100%',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    ...(open ? { right: 0 } : { right: '10px' }),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  };
});

export const StyledBoxContent = styled(Box)<StyledBoxContentProps>(({
  theme,
  open,
}) => {
  return {
    flexGrow: 1,
    width: '100%',
    position: 'relative',
    '.logo-nav': {
      display: 'block',
      position: 'relative',
      margin: '0 auto',
      transition: theme.transitions.create(
        ['width', 'height', 'margin-top', 'margin-bottom'],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.short,
        }
      ),
      ...(open
        ? {
            width: '100px',
            height: '100px',
            marginTop: `-${theme.spacing(2)}`,
            marginBottom: theme.spacing(1),
          }
        : {
            width: '50px',
            height: '50px',
            marginTop: `${theme.spacing(3)}`,
            marginBottom: `calc(${theme.spacing(2)} + 2px)`,
          }),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  };
});
