'use client';

import { Skeleton } from '@mui/material';

export function LoadingCampanhaView() {
  return (
    <>
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="155px"
        sx={{ borderRadius: '8px' }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="155px"
        sx={{ borderRadius: '8px' }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="155px"
        sx={{ borderRadius: '8px' }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="155px"
        sx={{ borderRadius: '8px' }}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="155px"
        sx={theme => ({
          gridColumn: 'span 1',
          borderRadius: '8px',
          [theme.breakpoints.down('xl')]: { gridColumn: 'span 2' },
          [theme.breakpoints.down('md')]: { gridColumn: 'span 1' },
        })}
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height="300px"
        sx={theme => ({
          gridColumn: 'span 3',
          borderRadius: '8px',
          [theme.breakpoints.down('lg')]: { gridColumn: 'span 2' },
          [theme.breakpoints.down('md')]: { gridColumn: 'span 1' },
        })}
      />
    </>
  );
}
