import { type ComponentProps } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { signOut } from 'next-auth/react';
import {
  ArrowForwardIos,
  ChevronLeft,
  ChevronRight,
  Logout,
} from '@mui/icons-material';
import { Box, Divider, IconButton, List, ListItemIcon } from '@mui/material';

import {
  StyledBoxContent,
  StyledListItemButton,
  StyledListItemText,
  StyledMainDrawer,
  StyledMenuButtonBox,
} from './elements';
import { navList } from './listNavigation';

export function MainNavigation({
  toggleMenu,
  isMobile,
  ...props
}: Omit<ComponentProps<typeof StyledMainDrawer>, 'variant'> & {
  toggleMenu: () => void;
  isMobile: boolean;
}) {
  const pathname = usePathname();

  function handleNavClick() {
    // Close menu on mobile navigation or small screens
    if (isMobile || window.innerWidth < 600) {
      toggleMenu();
    }
  }

  return (
    <StyledMainDrawer {...props} variant="permanent" data-testid="main-drawer">
      <StyledBoxContent open={props.open}>
        <StyledMenuButtonBox open={props.open}>
          <IconButton onClick={toggleMenu} data-testid="main-drawer-toggle">
            {props.open && <ChevronLeft />}
            {!props.open && <ChevronRight />}
          </IconButton>
        </StyledMenuButtonBox>
        <Image
          src="/static/logo_martins.png"
          alt="Martins Logo"
          width={100}
          height={100}
          className="logo-nav"
        />
        <List>
          {navList.map(item => (
            <Link href={item.href} legacyBehavior key={item.label}>
              <StyledListItemButton
                onClick={handleNavClick}
                className={item.href === pathname ? 'active' : ''}
              >
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  {item.icon && (
                    <ListItemIcon
                      sx={{
                        minWidth: 'inherit',
                        marginTop: '-3px',
                        color: 'currentcolor',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                  )}
                  <StyledListItemText open={props.open} inset={!item.icon}>
                    {item.label}
                  </StyledListItemText>
                </Box>
                <ListItemIcon
                  sx={{
                    minWidth: 'inherit',
                    marginTop: '-3px',
                    color: 'currentcolor',
                    textAlign: 'right',
                  }}
                >
                  <ArrowForwardIos sx={{ fontSize: '0.7rem' }} />
                </ListItemIcon>
              </StyledListItemButton>
            </Link>
          ))}
        </List>
      </StyledBoxContent>
      <Box
        sx={{
          flexGrow: 0,
          width: '100%',
          marginBottom: theme => theme.spacing(2),
        }}
      >
        <Divider />
        <List>
          <StyledListItemButton
            sx={{
              '&:hover': {
                color: theme => theme.palette.error.main,
                '.logout-icon': { color: theme => theme.palette.error.main },
              },
            }}
            onClick={() => signOut()}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <ListItemIcon
                className="logout-icon"
                sx={{
                  minWidth: 'inherit',
                  marginTop: '-3px',
                  color: theme => theme.palette.grey[400],
                  transition: theme =>
                    theme.transitions.create('color', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.short,
                    }),
                }}
              >
                <Logout />
              </ListItemIcon>
              <StyledListItemText open={props.open}>Sair</StyledListItemText>
            </Box>
          </StyledListItemButton>
        </List>
      </Box>
    </StyledMainDrawer>
  );
}

export * from './elements';
