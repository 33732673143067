'use client';

import React, { type SyntheticEvent, useEffect, useState } from 'react';
import Link from 'next/link';
import { redirect, useRouter } from 'next/navigation';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import {
  CampaignFormStepBanners,
  CampaignFormStepInformation,
  CampaignFormStepSegmentation,
  GET_CAMPAIGN_RESULTS_DETAILED_QUERY_KEY,
  GET_CAMPAIGN_RESULTS_RESUMED_QUERY_KEY,
  StatusSelectBadge,
  StyledCampaignFormSubtitle,
  StyledTabItem,
  useBreadcrumbs,
  useGetBannersKeys,
  useGetCampaign,
  useUpdateCampaign,
} from '~/entities';
import {
  ContentTitle,
  Dialog,
  SavingStatusBadge,
  StyledContainer,
} from '~/shared';

export function FormCampanhaView({
  id,
  searchParams,
}: {
  id?: string;
  searchParams?: { [key: string]: string | string[] | undefined };
}) {
  const router = useRouter();
  const breadcrumbs = useBreadcrumbs();

  const getBannerKeysQuery = useGetBannersKeys();
  const queryClient = useQueryClient();

  const stepParam =
    searchParams?.step && searchParams?.step !== ''
      ? (searchParams?.step as string)
      : 'dados';

  const [step, setStep] = useState(stepParam);
  const [isAppCampaign, setIsAppCampaign] = useState(false);
  const [isWebCampaign, setIsWebCampaign] = useState(false);
  const [stepOneFormError, setStepOneFormError] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isPublishingSuccess, setIsPublishingSuccess] = useState(false);

  if (!id) redirect('/campanhas');

  const getCampaignQuery = useGetCampaign(id);

  const updateCampaignQuery = useUpdateCampaign(() => {
    getCampaignQuery.refetch();
    queryClient.removeQueries({
      queryKey: [GET_CAMPAIGN_RESULTS_DETAILED_QUERY_KEY, id],
    });
    queryClient.removeQueries({
      queryKey: [GET_CAMPAIGN_RESULTS_RESUMED_QUERY_KEY, id],
    });
  });

  useEffect(() => {
    if (getCampaignQuery.isSuccess) {
      const campaign = getCampaignQuery.data;
      if (campaign.isApp) setIsAppCampaign(true);
      if (campaign.isWeb) setIsWebCampaign(true);

      if (!campaign.isApp && campaign.isWeb && stepParam === 'app') {
        setStep('web');
        router.push(`/campanhas/editar/${id}?step=web`);
      }

      if (!campaign.isWeb && campaign.isApp && stepParam === 'web') {
        setStep('app');
        router.push(`/campanhas/editar/${id}?step=app`);
      }

      if (!campaign.isWeb && !campaign.isApp) {
        setStep('dados');
        router.push(`/campanhas/editar/${id}?step=dados`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCampaignQuery.isSuccess]);

  function handleStepChange(_: SyntheticEvent, newValue: string) {
    setStep(newValue);
    router.push(`/campanhas/editar/${id}?step=${newValue}`);
    window.scrollTo({ top: 0 });
  }

  const contentTitle = getCampaignQuery.isLoading
    ? 'Carregando...'
    : getCampaignQuery.data?.name ?? '';

  function handleContinueActionFirstStep() {
    if (!stepOneFormError) {
      setStep('periodo');
      router.push(`/campanhas/editar/${id}?step=periodo`);
      window.scrollTo({ top: 0 });
    }
  }

  useEffect(() => {
    breadcrumbs.setPaths([
      { label: 'Campanhas', path: '/campanhas' },
      { label: contentTitle, path: `/campanhas/${id}` },
      { label: 'Editar', path: `/campanhas/${id}` },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentTitle]);

  return (
    <>
      <ContentTitle
        title={contentTitle}
        returnLink={`/campanhas/${id}`}
        actions={
          getCampaignQuery.isSuccess && (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <SavingStatusBadge
                isLoading={getCampaignQuery.isLoading}
                isSaving={updateCampaignQuery.isPending}
                lastSaveDate={getCampaignQuery.data.updatedAt}
                createdAt={getCampaignQuery.data.createdAt}
              />
              {getCampaignQuery.data?.status && (
                <StatusSelectBadge
                  campaignId={getCampaignQuery.data?.id}
                  campaignStatus={getCampaignQuery.data?.status}
                />
              )}
            </Box>
          )
        }
      />

      <StyledContainer>
        <TabContext value={step}>
          <TabList
            sx={{
              '.MuiTabs-flexContainer': {
                flexDirection: 'row',
                gap: 2,
              },
            }}
            onChange={handleStepChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <StyledTabItem
              label="Dados de campanha"
              value="dados"
              disabled={getCampaignQuery.isLoading}
            />
            <StyledTabItem
              label="Período e Segmentação"
              value="periodo"
              disabled={getCampaignQuery.isLoading}
            />
            <StyledTabItem
              label="Formato - Web"
              value="web"
              disabled={getCampaignQuery.isLoading || !isWebCampaign}
            />
            <StyledTabItem
              label="Formato - App"
              value="app"
              disabled={getCampaignQuery.isLoading || !isAppCampaign}
            />
          </TabList>

          <TabPanel
            sx={theme => ({ [theme.breakpoints.down('md')]: { p: 0 } })}
            value="dados"
          >
            {getCampaignQuery.isSuccess && (
              <CampaignFormStepInformation
                campaignId={id}
                campaign={getCampaignQuery.data}
                updateCampaignQuery={updateCampaignQuery}
                getCampaignQuery={getCampaignQuery}
                setStepOneFormError={setStepOneFormError}
                setIsWebCampaign={setIsWebCampaign}
                setIsAppCampaign={setIsAppCampaign}
                continueAction={() => handleContinueActionFirstStep()}
                cancelActionNode={
                  <Link href={`/campanhas/${id}`} passHref>
                    <Button size="large" sx={{ px: 3, py: 1.5 }}>
                      Sair
                    </Button>
                  </Link>
                }
              />
            )}
            {getCampaignQuery.isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  mt: 4,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="56px"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="56px"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="56px"
                />
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  sx={{ width: '400px', height: '56px' }}
                />
              </Box>
            )}
          </TabPanel>

          <TabPanel
            sx={theme => ({ [theme.breakpoints.down('md')]: { p: 0 } })}
            value="periodo"
          >
            {getCampaignQuery.isSuccess && (
              <CampaignFormStepSegmentation
                campaign={getCampaignQuery.data}
                updateCampaignQuery={updateCampaignQuery}
                continueAction={() => {
                  let stepToGo = isWebCampaign ? 'web' : 'app';
                  stepToGo = isAppCampaign && !isWebCampaign ? 'app' : stepToGo;
                  setStep(stepToGo);
                  router.push(`/campanhas/editar/${id}?step=${stepToGo}`);
                }}
                cancelActionNode={
                  <Button
                    size="large"
                    onClick={() => {
                      setStep('dados');
                      router.push(`/campanhas/editar/${id}?step=dados`);
                    }}
                    sx={{ px: 3, py: 1.5 }}
                  >
                    Voltar
                  </Button>
                }
              />
            )}

            {getCampaignQuery.isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  mt: 4,
                }}
              >
                <StyledCampaignFormSubtitle>
                  Tempo de atuação
                </StyledCampaignFormSubtitle>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="56px"
                />
                <StyledCampaignFormSubtitle>
                  Segmentação
                </StyledCampaignFormSubtitle>
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="56px"
                />
              </Box>
            )}
          </TabPanel>

          {isWebCampaign && (
            <TabPanel
              sx={theme => ({ [theme.breakpoints.down('md')]: { p: 0 } })}
              value="web"
            >
              <CampaignFormStepBanners
                campaignId={id}
                bannerType="web"
                updateCampaignQuery={updateCampaignQuery}
                campaignStatus={getCampaignQuery.data?.status}
                bannerKeys={getBannerKeysQuery.data?.web}
                campaignBanners={getCampaignQuery.data?.webBanners}
                linkUrl={getCampaignQuery.data?.webLink}
                activeLink={getCampaignQuery.data?.webLinkActive}
                continueLabel={
                  // eslint-disable-next-line no-nested-ternary
                  isAppCampaign
                    ? 'Continuar'
                    : getCampaignQuery.data?.status !== 'ACTIVE'
                      ? 'Publicar Campanha'
                      : 'Salvar e Sair'
                }
                continueAction={() => {
                  if (isAppCampaign) {
                    setStep('app');
                    router.push(`/campanhas/editar/${id}?step=app`);
                    return;
                  }
                  if (getCampaignQuery.data?.status !== 'ACTIVE') {
                    setIsPublishing(true);
                  } else {
                    router.push(`/campanhas/${id}`);
                  }
                }}
                cancelActionNode={
                  <Button
                    size="large"
                    onClick={() => {
                      setStep('periodo');
                      router.push(`/campanhas/editar/${id}?step=periodo`);
                    }}
                    sx={{ px: 3, py: 1.5 }}
                  >
                    Voltar
                  </Button>
                }
              />
            </TabPanel>
          )}

          {isAppCampaign && (
            <TabPanel
              sx={theme => ({ [theme.breakpoints.down('md')]: { p: 0 } })}
              value="app"
            >
              <CampaignFormStepBanners
                campaignId={id}
                bannerType="app"
                updateCampaignQuery={updateCampaignQuery}
                campaignStatus={getCampaignQuery.data?.status}
                bannerKeys={getBannerKeysQuery.data?.app}
                campaignBanners={getCampaignQuery.data?.appBanners}
                linkUrl={getCampaignQuery.data?.appLink}
                activeLink={getCampaignQuery.data?.appLinkActive}
                continueLabel={
                  getCampaignQuery.data?.status === 'DRAFT'
                    ? 'Publicar Campanha'
                    : 'Salvar e Sair'
                }
                continueAction={() => {
                  if (getCampaignQuery.data?.status === 'DRAFT') {
                    setIsPublishing(true);
                  } else {
                    router.push(`/campanhas/${id}`);
                  }
                }}
                cancelActionNode={
                  <Button
                    size="large"
                    onClick={() => {
                      const stepToGo = isWebCampaign ? 'web' : 'periodo';
                      setStep(stepToGo);
                      router.push(`/campanhas/editar/${id}?step=${stepToGo}`);
                    }}
                    sx={{ px: 3, py: 1.5 }}
                  >
                    Voltar
                  </Button>
                }
              />
            </TabPanel>
          )}
        </TabContext>
      </StyledContainer>

      {/** TODO: Extract this as a Campaign Dialog */}
      {isPublishing && (
        <Dialog withCloseButton={false} open>
          {!updateCampaignQuery.isPending ? (
            <Typography
              sx={{ m: 0, color: 'primary.main', textAlign: 'center' }}
              variant="h4"
            >
              Você esta prestes a publicar a campanha!
            </Typography>
          ) : (
            <Typography
              sx={{ m: 0, color: 'primary.main', textAlign: 'center' }}
              variant="h4"
            >
              Publicando <CircularProgress size={15} />
            </Typography>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
            <Button
              disabled={updateCampaignQuery.isPending}
              size="large"
              variant="outlined"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                setIsPublishing(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={updateCampaignQuery.isPending}
              size="large"
              variant="contained"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                updateCampaignQuery.mutate(
                  {
                    id,
                    status: 'ACTIVE',
                  },
                  {
                    onSuccess: () => {
                      setIsPublishing(false);
                      setIsPublishingSuccess(true);
                    },
                  }
                );
              }}
            >
              Publicar
            </Button>
          </Box>

          <Typography
            sx={{
              fontStyle: 'italic',
              p: 2,
              fontSize: '14px',
              textAlign: 'center',
            }}
          >
            Lembrando que você pode alterar o status da campanha a qualquer
            momento depois sem perder os dados de performance.
          </Typography>
        </Dialog>
      )}

      {isPublishingSuccess && (
        <Dialog withCloseButton={false} open>
          <Typography
            sx={{ m: 0, color: 'primary.main', textAlign: 'center' }}
            variant="h4"
          >
            Campanha publicada com sucesso!
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4, gap: 2 }}>
            <Button
              size="large"
              variant="contained"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                setIsPublishingSuccess(false);
                router.push(`/campanhas/${id}`);
              }}
            >
              Ver dashboard
            </Button>
            <Button
              size="large"
              variant="outlined"
              sx={{ px: 3, py: 1.5 }}
              onClick={() => {
                setIsPublishingSuccess(false);
              }}
            >
              Continuar editando
            </Button>
          </Box>
        </Dialog>
      )}
    </>
  );
}
