'use client';

import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import SaveAltSharpIcon from '@mui/icons-material/SaveAltSharp';
import { Box, Button, CircularProgress } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, formatDate } from 'date-fns';
import dayjs from 'dayjs';

import {
  BannersKeysSelect,
  getReportsFilter,
  getReportsFilterTotal,
  StatusSelect,
  useBreadcrumbs,
  useDownloadReportsFilter,
} from '~/entities';
import { ReportAdvertisersList } from '~/entities/Reports/components';
import { TypeSegmentSelect } from '~/entities/Segments/components/TypeSegmentSelect';
import {
  ContentTitle,
  datePickerSlotProps,
  Pagination,
  SearchBox,
  StyledContainer,
  StyledTableContainer,
} from '~/shared';
import {
  BannersKey,
  CampaignStatus,
  ReportsFilterDto,
  ReportsFilterModelView,
  SegmentFromList,
} from '~/types';

import { PlatformSelect } from './components/PlatformSelect';

const REPORTS_PAGE_SIZE = 15;

export function RelatoriosView() {
  const breadcrumbs = useBreadcrumbs();

  const [searchQuery, setSearchQuery] = useState('');
  const [status, setStatus] = useState<'' | `${CampaignStatus}`>('');
  const [segments, setSegments] = useState<SegmentFromList[]>([]);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const [bannersPositions, setBannersPositions] = useState<
    Pick<BannersKey, 'key' | 'name'>[]
  >([]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [data, setData] = useState<ReportsFilterModelView | null>(null);
  const [resumeData, setResumeData] = useState<ReportsFilterModelView | null>(
    null
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const isValid = isLoading || Boolean(endDateError);

  const [reportsFilter, setReportsFilter] = useState<ReportsFilterDto>({
    query: searchQuery,
    count: REPORTS_PAGE_SIZE,
    page: 1,
  });

  const handleGetFilterReports = async () => {
    setIsLoading(true);
    setIsError(false);
    setError(null);

    try {
      const segmentsIds: { id: string }[] = [];
      segments.forEach(s => {
        s.segmentSelections?.forEach(segmentSelect => {
          segmentsIds.push({ id: segmentSelect.id });
        });
      });

      const transformedBannerRequests = bannersPositions.map(banner => ({
        key: banner.key,
      }));

      const response = await getReportsFilter({
        ...reportsFilter,
        segments: segmentsIds,
        devices: platforms,
        bannerRequests: JSON.stringify(transformedBannerRequests),
      });

      const responseTotal = await getReportsFilterTotal(reportsFilter);

      setResumeData(responseTotal?.data);
      setData(response?.data);
    } catch (err) {
      setIsError(true);
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadReportsFilterMutation = useDownloadReportsFilter(
    downloadData => {
      const nowFormatted = format(new Date(), 'dd-MM-yyyy_HH-mm');
      const filename = `Relatório Consolidado - ${nowFormatted}.xlsx`;
      const blob = new Blob([downloadData.data], {
        type: downloadData.headers['content-type'],
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename;
      a.click();
    }
  );

  const total = data?.total ? Number(data?.total) : 0;

  const totalPages = Math.ceil(total / REPORTS_PAGE_SIZE);

  useEffect(() => {
    breadcrumbs.setPaths([{ label: 'Relatórios', path: '/relatorios' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function nextPageHandler() {
    if (reportsFilter.page < totalPages) {
      setReportsFilter(state => ({ ...state, page: state.page + 1 }));
    }
  }

  function prevPageHandler() {
    if (reportsFilter.page > 1) {
      setReportsFilter(state => ({ ...state, page: state.page - 1 }));
    }
  }

  function handleDownloadConsolidatedReport() {
    downloadReportsFilterMutation.mutate(reportsFilter);
  }

  useDebounce(
    () => {
      setReportsFilter(state => ({
        ...state,
        query: searchQuery,
        page: 1,
      }));
    },
    600,
    [searchQuery]
  );

  useDebounce(
    () => {
      setReportsFilter(state => ({
        ...state,
        status: status === '' ? undefined : status,
        page: 1,
      }));
    },
    1,
    [status]
  );

  useDebounce(
    () => {
      if (startDate === null) {
        setStartDateError('');
        setReportsFilter(state => ({
          ...state,
          startDate: undefined,
          page: 1,
        }));
        return;
      }

      if (Number.isNaN(startDate.getTime())) {
        setStartDateError('Data invalida');
        return;
      }

      setStartDateError('');
      setReportsFilter(state => ({
        ...state,
        startDate: formatDate(startDate, 'dd-MM-yyyy'),
        page: 1,
      }));
    },
    1,
    [startDate]
  );

  useDebounce(
    () => {
      if (endDate === null) {
        setEndDateError('');
        setReportsFilter(state => ({
          ...state,
          endDate: undefined,
          page: 1,
        }));
        return;
      }

      if (Number.isNaN(endDate.getTime())) {
        setEndDateError('Data invalida');
        return;
      }

      if (startDate && endDate < startDate) {
        setEndDateError('A data final não pode ser menor que a data inicial');
        return;
      }

      setEndDateError('');
      setReportsFilter(state => ({
        ...state,
        endDate: formatDate(endDate, 'dd-MM-yyyy'),
        page: 1,
      }));
    },
    1,
    [endDate]
  );

  const DisableRetroactiveDates = (date: Date | null) => {
    if (!startDate || !date) return false;
    const dayjsDate = dayjs(date);
    const dayjsStartDate = dayjs(startDate);
    return dayjsDate.isBefore(dayjsStartDate.startOf('day'));
  };

  return (
    <>
      <ContentTitle title="Relatórios" isFetching={isLoading} />

      <StyledContainer>
        <Box
          sx={theme => ({
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            mb: 4,
            [theme.breakpoints.down('lg')]: { flexDirection: 'column' },
          })}
        >
          <Box
            sx={theme => ({
              display: 'flex',
              gap: 2,
              width: '100%',
              [theme.breakpoints.down('lg')]: { flexDirection: 'column' },
            })}
          >
            <SearchBox
              label="Procurar por Nome ou ID"
              onChange={value => setSearchQuery(value)}
              value={searchQuery}
              fullWidth
            />

            <DatePicker
              label="A partir de"
              value={startDate}
              onChange={value => setStartDate(value)}
              slotProps={datePickerSlotProps(startDateError)}
            />

            <DatePicker
              label="Até"
              value={endDate}
              onChange={value => setEndDate(value)}
              slotProps={datePickerSlotProps(endDateError)}
              shouldDisableDate={DisableRetroactiveDates}
            />
          </Box>

          {/* <SegmentsSelect
              id="segment-select"
              label="Tipo de Segmentação"
              value={segmentationType}
              onChange={value => {
                setSegmentationType(value);
              }}
              segment="tipoNegocio"
              multiple={false}
              // disabled
            /> */}

          <TypeSegmentSelect
            label="Tipo de Segmentação"
            segments={segments}
            setSegments={setSegments}
          />

          {/* <SegmentsSelect
              id="retails-select"
              label="Departamento"
              value={segments}
              onChange={value => {
                setSegments(value);
              }}
              segment="departamentos"
              // disabled
            /> */}
          <Box
            sx={theme => ({
              display: 'flex',
              gap: 2,
              width: '100%',
              alignItems: 'center',
              [theme.breakpoints.down('lg')]: {
                flexDirection: 'column',
              },
            })}
          >
            <PlatformSelect
              id="metric-select"
              label="Por Plataforma"
              setter={setPlatforms}
              selectedPlatforms={platforms}
            />
            <BannersKeysSelect
              id="metric-select"
              label="Local do Banner"
              onChange={value => setBannersPositions(value)}
              value={bannersPositions}
            />
            <StatusSelect
              id="banners-select"
              setter={setStatus}
              selectedStatus={status}
              fullWidth
            />
            <Button
              variant="contained"
              onClick={handleGetFilterReports}
              disabled={isValid}
              size="medium"
              sx={{ maxHeight: 64, padding: '12px 64px' }}
            >
              Filtrar
            </Button>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              mt: 1,
            }}
          >
            <Button
              variant="contained"
              startIcon={
                downloadReportsFilterMutation.isPending ? (
                  <CircularProgress size={15} />
                ) : (
                  <SaveAltSharpIcon />
                )
              }
              sx={{ textTransform: 'none' }}
              onClick={handleDownloadConsolidatedReport}
              disabled={downloadReportsFilterMutation.isPending}
            >
              Baixar relatório consolidado
            </Button>
          </Box>
        </Box>

        <StyledTableContainer>
          <ReportAdvertisersList
            data={data}
            resumeData={resumeData}
            isLoading={isLoading}
            isError={isError}
            error={error}
          />
        </StyledTableContainer>
        <Pagination
          disabled={totalPages === 1 || isLoading || total === 0}
          page={reportsFilter.page}
          count={totalPages}
          nextPage={nextPageHandler}
          prevPage={prevPageHandler}
          onChange={(_, newPage) =>
            setReportsFilter(state => ({
              ...state,
              page: newPage,
            }))
          }
        />
      </StyledContainer>
    </>
  );
}
