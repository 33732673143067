'use client';

import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import Link from 'next/link';
import { Box, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { formatDate } from 'date-fns';
import dayjs from 'dayjs';

import {
  DashCards,
  DashList,
  StatusSelect,
  useBreadcrumbs,
  useGetDashboard,
} from '~/entities';
import { ContentTitle, StyledContainer, StyledTableContainer } from '~/shared';
import { CampaignStatus, DashCampaignsDto } from '~/types';

import { StarterView } from '../starter';

export function DashboardView() {
  const breadcrumbs = useBreadcrumbs();

  const [status, setStatus] = useState<'' | `${CampaignStatus}`>('');

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [finalDate, setFinalDate] = useState<Date | null>(null);

  const [startDateError, setStartDateError] = useState('');
  const [finalDateError, setFinalDateError] = useState('');

  const [getDashboardFilter, setGetDashboardFilter] =
    useState<DashCampaignsDto>({});

  const getDashboardQuery = useGetDashboard(getDashboardFilter);

  useEffect(() => {
    breadcrumbs.setPaths([{ label: 'Dashboard', path: '/dashboard' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebounce(
    () => {
      setGetDashboardFilter(state => ({
        ...state,
        status: status === '' ? undefined : status,
      }));
    },
    1,
    [status]
  );

  useDebounce(
    () => {
      if (startDate === null) {
        setStartDateError('');
        setGetDashboardFilter(state => ({ ...state, startDate: undefined }));
        return;
      }

      if (Number.isNaN(startDate.getTime())) {
        setStartDateError('Data invalida');
        return;
      }

      setStartDateError('');
      setGetDashboardFilter(state => ({
        ...state,
        startDate: formatDate(startDate, 'dd-MM-yyyy'),
      }));
    },
    1,
    [startDate]
  );

  useDebounce(
    () => {
      if (finalDate === null) {
        setFinalDateError('');
        setGetDashboardFilter(state => ({ ...state, finishDate: undefined }));
        return;
      }

      if (Number.isNaN(finalDate.getTime())) {
        setFinalDateError('Data invalida');
        return;
      }

      if (startDate && finalDate < startDate) {
        setFinalDateError('A data final não pode ser menor que a data inicial');
        return;
      }

      setFinalDateError('');
      setGetDashboardFilter(state => ({
        ...state,
        finishDate: formatDate(finalDate, 'dd-MM-yyyy'),
      }));
    },
    1,
    [finalDate]
  );

  const DisableRetroactiveDates = (date: Date | null) => {
    if (!startDate || !date) return false;
    const dayjsDate = dayjs(date);
    const dayjsStartDate = dayjs(startDate);
    return dayjsDate.isBefore(dayjsStartDate.startOf('day'));
  };

  if (getDashboardQuery.data?.total === '0' && !getDashboardQuery.isLoading) {
    return <StarterView />;
  }

  return (
    <>
      <ContentTitle
        title="Dashboard"
        isFetching={getDashboardQuery.isLoading || getDashboardQuery.isFetching}
        actions={
          <>
            <DatePicker
              sx={{ width: '100%' }}
              label="Data inicial"
              value={startDate}
              onChange={value => setStartDate(value)}
              slotProps={{
                textField: {
                  error: !!startDateError,
                  helperText: startDateError,
                  variant: 'filled',
                },
                actionBar: {
                  actions: ['clear'],
                },
              }}
            />

            <DatePicker
              sx={{ width: '100%' }}
              label="Data final"
              value={finalDate}
              onChange={value => setFinalDate(value)}
              slotProps={{
                textField: {
                  error: !!finalDateError,
                  helperText: finalDateError,
                  variant: 'filled',
                },
                actionBar: {
                  actions: ['clear'],
                },
              }}
              shouldDisableDate={DisableRetroactiveDates}
            />

            <StatusSelect
              fullWidth
              selectedStatus={status}
              setter={setStatus}
            />
          </>
        }
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2rem',
          width: '100%',
        }}
      >
        <DashCards getDashboardsQuery={getDashboardQuery} />
        <StyledContainer>
          <Box
            sx={theme => ({
              display: 'flex',
              gap: 2,
              mb: 0,
              [theme.breakpoints.down('lg')]: { flexDirection: 'column' },
            })}
          >
            <StyledTableContainer>
              <Box
                sx={() => ({
                  display: 'flex',
                  justifyContent: 'space-between',
                })}
              >
                <Typography
                  sx={() => ({
                    typography: 'h6',
                  })}
                >
                  Melhores campanhas
                </Typography>
                <Link href="campanhas">
                  <Typography
                    sx={() => ({
                      textDecoration: 'underline',
                      color: '#2B52DD',
                      cursor: 'pointer',
                      fontWeight: '500',
                    })}
                  >
                    Ver todas as campanhas
                  </Typography>
                </Link>
              </Box>

              <DashList getDashboardQuery={getDashboardQuery} />
            </StyledTableContainer>
          </Box>
        </StyledContainer>
      </div>
    </>
  );
}
