import {
  AnunciantesIcon,
  CampanhasIcon,
  DashboardIcon,
  GerenciamentoIcon,
  RelatoriosIcon,
} from '~/shared';
import labelMenu from '~/types/contants/labelMenu';

export const navList = [
  {
    label: labelMenu.dashboard,
    icon: <DashboardIcon />,
    href: '/dashboard',
  },
  {
    label: labelMenu.campaign,
    icon: <CampanhasIcon />,
    href: '/campanhas',
  },
  {
    label: labelMenu.advertisers,
    icon: <AnunciantesIcon />,
    href: '/anunciantes',
  },
  {
    label: labelMenu.reports,
    icon: <RelatoriosIcon />,
    href: '/relatorios',
  },
  {
    label: labelMenu.users,
    icon: <GerenciamentoIcon />,
    href: '/gerenciamento',
  },
];
