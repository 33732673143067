/* eslint-disable no-console */

'use client';

import { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import {
  CampaignCreatedModal,
  CampaignFormStepCreation,
  StyledTabItem,
  useBreadcrumbs,
  useCreateCampaign,
} from '~/entities';
import { ContentTitle, StyledContainer } from '~/shared';
import type { CreateCampaignDto } from '~/types';

export function NovaCampanhaView() {
  const breadcrumbs = useBreadcrumbs();

  const [isAppCampaign, setIsAppCampaign] = useState(false);
  const [isWebCampaign, setIsWebCampaign] = useState(false);
  const [isCampaignCreated, setIsCampaignCreated] = useState<string | null>(
    null
  );

  const createCampaignMutation = useCreateCampaign(data => {
    setIsCampaignCreated(data.data.id);
  });

  function handleCreationStepContinue(createObject: CreateCampaignDto) {
    createCampaignMutation.mutate(createObject);
  }

  useEffect(() => {
    breadcrumbs.setPaths([
      { label: 'Campanhas', path: '/campanhas' },
      { label: 'Nova Campanha', path: '/campanhas/nova' },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentTitle title="Nova Campanha" />

      <StyledContainer>
        <TabContext value="1">
          <TabList variant="scrollable" scrollButtons allowScrollButtonsMobile>
            <StyledTabItem label="Dados de campanha" value="1" />
            <StyledTabItem label="Período e Segmentação" disabled />
            <StyledTabItem label="Formato - Web" disabled />
            <StyledTabItem label="Formato - App" disabled />
          </TabList>

          <TabPanel value="1">
            <CampaignFormStepCreation
              isWebCampaign={isWebCampaign}
              setIsWebCampaign={setIsWebCampaign}
              isAppCampaign={isAppCampaign}
              setIsAppCampaign={setIsAppCampaign}
              continueAction={handleCreationStepContinue}
              isLoadingAction={createCampaignMutation.isPending}
            />
          </TabPanel>
        </TabContext>
      </StyledContainer>

      {isCampaignCreated && <CampaignCreatedModal id={isCampaignCreated} />}
    </>
  );
}
