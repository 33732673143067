import { Box } from '@mui/material';

import { StyledContainerBox, StyledContentBox } from './elements';

export function MainContent({
  children,
  hasHeader,
  ...props
}: Omit<React.ComponentProps<typeof Box>, 'component'> & {
  hasHeader?: boolean;
}) {
  return (
    <StyledContentBox
      component="main"
      sx={
        hasHeader
          ? {
              mt: theme => `${theme.uiSizes.headerHeight}px`,
              scrollBehavior: 'smooth',
              ...props.sx,
            }
          : { scrollBehavior: 'smooth', ...props.sx }
      }
      {...props}
    >
      <StyledContainerBox
        data-testid="content-container"
        sx={{ mt: hasHeader ? 5 : 0 }}
      >
        {children}
      </StyledContainerBox>
    </StyledContentBox>
  );
}
