'use client';

import { useEffect } from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';

import { CreateCampaignButton, useBreadcrumbs } from '~/entities';

export function StarterView() {
  const breadcrumbs = useBreadcrumbs();

  useEffect(() => {
    breadcrumbs.setPaths([{ label: 'Dashboard', path: '/dashboard' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: theme => `calc(100vh - ${theme.uiSizes.headerHeight * 2}px)`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          height: '100%',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Box sx={{ maxWidth: '400px' }}>
          <Typography
            variant="h4"
            color="primary"
            sx={{ fontWeight: 500, textAlign: 'center' }}
          >
            Bem vindo ao sistema Martins ADS
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ textAlign: 'center', my: 2 }}
          >
            Crie sua primeira campanha para ter acesso as métricas, andamento
            dos anúncios e taxas de conversão.
          </Typography>
          <CreateCampaignButton forceShow fullWidth />
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: '20px',
          bottom: '20px',
          width: '45%',
          height: '100%',
        }}
      >
        <Image
          src="/static/martins_logo_background.svg"
          alt="Martins Logo"
          fill
          sizes="100% 100%"
          style={{
            objectFit: 'contain',
            objectPosition: 'bottom right',
          }}
        />
      </Box>
    </Box>
  );
}
