'use client';

import { type ComponentProps, useState } from 'react';
import type { Session } from 'next-auth';
import { Box } from '@mui/material';

import { MartinsTokenRefreshSession, useUserNonce } from '~/features';
import { COOKIE_KEYS, type SetCookieFunction } from '~/shared';
import { AppHeader, MainContent, MainNavigation } from '~/widgets';

type MainLayoutProps = ComponentProps<typeof Box> & {
  setCookie: SetCookieFunction;
  userMenuState: boolean;
  session: Session | null;
  isMobile: boolean;
  userNonce?: string;
};

export function MainLayout({
  children,
  sx,
  setCookie,
  userMenuState,
  session,
  isMobile,
  userNonce,
  ...props
}: MainLayoutProps) {
  useUserNonce(setCookie, userNonce);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(
    isMobile ? false : userMenuState ?? true
  );

  const toggleMenu = () => {
    setCookie(COOKIE_KEYS.USER_MENU_STATE, !isMenuOpen ? 'open' : 'closed');
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Box {...props} sx={{ ...sx, display: 'flex' }} data-testid="root-box">
      {!!session && (
        <>
          <MartinsTokenRefreshSession st={session.t.st} />
          <AppHeader
            session={session}
            open={isMenuOpen}
            toggleMenu={toggleMenu}
          />
          <MainNavigation
            open={isMenuOpen}
            toggleMenu={toggleMenu}
            isMobile={isMobile}
          />
        </>
      )}
      <MainContent hasHeader={!!session}>{children}</MainContent>
    </Box>
  );
}
