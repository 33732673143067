'use client';

import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { AddCircleOutline } from '@mui/icons-material';
import { Divider } from '@mui/material';

import {
  AdvertisersList,
  CreateAdvertiserDialog,
  DeleteAdvertiserDialog,
  UpdateAdvertiserDialog,
  useBreadcrumbs,
  useGetAdvertisers,
} from '~/entities';
import {
  ActionButton,
  ContentTitle,
  Pagination,
  SearchBox,
  StyledContainer,
  StyledTableContainer,
} from '~/shared';
import type { Advertiser, AdvertiserListDTO } from '~/types';

const ADVERTISER_PAGE_SIZE = 15;

export function AnunciantesView() {
  const breadcrumbs = useBreadcrumbs();

  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [selectedAdvertiser, setSelectedAdvertiser] =
    useState<Advertiser | null>(null);
  const [advertiserQuery, setAdvertiserQuery] = useState('');
  const [advertisersFilter, setAdvertisersFilter] = useState<AdvertiserListDTO>(
    {
      query: advertiserQuery,
      count: ADVERTISER_PAGE_SIZE,
      page: 1,
    }
  );

  const useGetAdvertisersQuery = useGetAdvertisers(advertisersFilter);

  const total = useGetAdvertisersQuery.data?.total ?? 0;
  const totalPages = Math.ceil(total / ADVERTISER_PAGE_SIZE);

  useEffect(() => {
    breadcrumbs.setPaths([{ label: 'Anunciantes', path: '/anunciantes' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDebounce(
    () => {
      setAdvertisersFilter(state => ({ ...state, query: advertiserQuery }));
    },
    500,
    [advertiserQuery]
  );

  function nextPageHandler() {
    if (advertisersFilter.page < totalPages) {
      setAdvertisersFilter(state => ({
        ...state,
        page: state.page + 1,
      }));
    }
  }

  function prevPageHandler() {
    if (advertisersFilter.page > 1) {
      setAdvertisersFilter(state => ({
        ...state,
        page: state.page - 1,
      }));
    }
  }

  function handleOnDeleteClick(advertiser: Advertiser) {
    setSelectedAdvertiser(advertiser);
    setIsOpenDeleteDialog(true);
  }

  function handleOnEditClick(advertiser: Advertiser) {
    setSelectedAdvertiser(advertiser);
    setIsOpenEditDialog(true);
  }

  function handleRefetchList() {
    useGetAdvertisersQuery.refetchAll();
  }

  function handleOnCloseDialog() {
    setIsOpenCreateDialog(false);
    setIsOpenDeleteDialog(false);
    setIsOpenEditDialog(false);

    if (advertisersFilter.page > totalPages) {
      setAdvertisersFilter(state => ({
        ...state,
        page: totalPages,
      }));
    }
  }

  return (
    <>
      <ContentTitle
        title="Anunciantes"
        actions={
          <ActionButton
            startIcon={<AddCircleOutline />}
            onClick={() => setIsOpenCreateDialog(true)}
          >
            Novo Anunciante
          </ActionButton>
        }
        isFetching={useGetAdvertisersQuery.isRefetching}
      />
      <StyledContainer>
        <SearchBox
          id="advertisers-query"
          label="Procurar por Nome ou ID"
          isLoading={useGetAdvertisersQuery.isLoading}
          onChange={setAdvertiserQuery}
          value={advertiserQuery}
        />
        <Divider sx={{ border: 0, m: '15px' }} />
        <StyledTableContainer>
          <AdvertisersList
            useGetAdvertisersQuery={useGetAdvertisersQuery}
            onDelete={handleOnDeleteClick}
            onEdit={handleOnEditClick}
          />
        </StyledTableContainer>
        <Pagination
          disabled={totalPages === 1 || useGetAdvertisersQuery.isFetching}
          page={advertisersFilter.page}
          count={totalPages}
          nextPage={nextPageHandler}
          prevPage={prevPageHandler}
          onChange={(_, newPage) =>
            setAdvertisersFilter(state => ({
              ...state,
              page: newPage,
            }))
          }
        />
      </StyledContainer>

      {isOpenCreateDialog && (
        <CreateAdvertiserDialog
          open={isOpenCreateDialog}
          refetchList={handleRefetchList}
          onClose={handleOnCloseDialog}
        />
      )}

      {isOpenDeleteDialog && (
        <DeleteAdvertiserDialog
          open={!!isOpenDeleteDialog}
          refetchList={handleRefetchList}
          onClose={handleOnCloseDialog}
          onCloseTransitionEnded={() => setSelectedAdvertiser(null)}
          selectedAdvertiser={selectedAdvertiser}
        />
      )}

      {isOpenEditDialog && (
        <UpdateAdvertiserDialog
          open={!!isOpenEditDialog}
          refetchList={handleRefetchList}
          onClose={handleOnCloseDialog}
          onCloseTransitionEnded={() => setSelectedAdvertiser(null)}
          selectedAdvertiser={selectedAdvertiser}
        />
      )}
    </>
  );
}
