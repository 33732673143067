import isPropValid from '@emotion/is-prop-valid';
import { Box, styled } from '@mui/material';

export const StyledContentBox = styled(Box, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#eff0f6' : '#1e1e20',
  color: theme.palette.text.primary,
  flexGrow: 1,
  height: `calc(100vh - ${theme.uiSizes.headerHeight}px)`,
  overflow: 'auto',
}));

export const StyledContainerBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  marginLeft: 0,
  maxWidth: '100%',
  paddingLeft: 4,
  paddingRight: 4,
  [theme.breakpoints.down('md')]: {
    paddingLeft: 1,
    paddingRight: 1,
  },
}));
