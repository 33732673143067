'use client';

import isPropValid from '@emotion/is-prop-valid';
import {
  AppBar as MuiAppBar,
  type AppBarProps,
  Box,
  IconButton,
  type IconButtonProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledAppBarProps extends AppBarProps {
  open?: boolean;
}

interface StyledMenuButtonProps extends IconButtonProps {
  open?: boolean;
}

export const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<StyledAppBarProps>(({ theme, open }) => ({
  height: theme.uiSizes.headerHeight,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[0],
  zIndex: theme.zIndex.drawer + 1,
  justifyContent: 'center',
  width: `calc(100% - ${theme.uiSizes.drawerWidthClosed}px)`,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
  ...(open && {
    marginLeft: theme.uiSizes.drawerWidth,
    width: `calc(100% - ${theme.uiSizes.drawerWidth}px)`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  }),
}));

export const StyledToolbar = styled(Box, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})(() => ({
  paddingRight: 24,
  userSelect: 'none',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-between',
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledHeaderContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 1,
  // flexWrap: 'wrap-reverse',
  // [theme.breakpoints.down('md')]: {
  //   flexWrap: 'nowrap',
  // },
}));

export const StyledLeftBox = styled(Box, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: theme.spacing(3),
  transition: theme.transitions.create(['margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
  '.mobile-logo-header': {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export const StyledMenuButton = styled(IconButton, {
  shouldForwardProp: (prop: string) => isPropValid(prop),
})<StyledMenuButtonProps>(({ theme, open = true }) => ({
  color: 'inherit',
  transition: theme.transitions.create(['margin', 'opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
  ...(open && {}),
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const StyledRightBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  paddingLeft: theme.spacing(2),
}));
