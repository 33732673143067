import React from 'react';
import Image from 'next/image';
import { type Session } from 'next-auth';
import { Close as CloseIcon, Menu as MenuIcon } from '@mui/icons-material';

import { Breadcrumbs, CreateCampaignButton, UserContextMenu } from '~/entities';

// import { ThemeButton } from '~/features';
import {
  StyledAppBar,
  StyledHeaderContentBox,
  StyledLeftBox,
  StyledMenuButton,
  StyledRightBox,
  StyledToolbar,
} from './elements';

export function AppHeader({
  open,
  position,
  toggleMenu,
  session,
  ...props
}: React.ComponentProps<typeof StyledAppBar> & {
  open: boolean;
  toggleMenu: () => void;
  session: Session;
}) {
  return (
    <StyledAppBar {...props} position={position ?? 'absolute'} open={open}>
      <StyledToolbar>
        <StyledHeaderContentBox>
          <StyledLeftBox>
            <Image
              src="/static/logo_martins_side.svg"
              alt="Martins Logo"
              width={100}
              height={27}
              className="mobile-logo-header"
            />
            <Breadcrumbs />
          </StyledLeftBox>

          <StyledRightBox>
            <CreateCampaignButton />
            <UserContextMenu session={session} />
            {/* <ThemeButton /> */}
            <StyledMenuButton
              open={open}
              aria-label="open drawer"
              onClick={toggleMenu}
              data-testid="header-menu-button"
            >
              {!open && <MenuIcon />}
              {open && <CloseIcon />}
            </StyledMenuButton>
          </StyledRightBox>
        </StyledHeaderContentBox>
      </StyledToolbar>
    </StyledAppBar>
  );
}
