'use client';

import { useState } from 'react';
import Image from 'next/image';

import {
  LoadingSplashScreen,
  LoginForm,
  SplashScreen,
  StyledGridBox,
  StyledHeader,
  StyledLoginFormBox,
  StyledTextCall,
  StyledThemeButtonBox,
  // ProvidersLogin
} from '~/entities';
import { type AuthProviders, ThemeButton } from '~/features';

export function LoginView({
  callbackUrl,
  errorParam,
}: {
  callbackUrl: string;
  errorParam?: string;
}) {
  const [isSubmitting, setIsSubmitting] = useState<`${AuthProviders}` | null>(
    null
  );

  return (
    <StyledGridBox>
      {!!isSubmitting && <LoadingSplashScreen isSubmitting={isSubmitting} />}
      <StyledThemeButtonBox>
        <ThemeButton />
      </StyledThemeButtonBox>
      <SplashScreen />
      <StyledLoginFormBox>
        <Image
          className="mobile-logo"
          src="/static/logo_martins_ads.svg"
          alt="Logo da Martins Ads"
          width={100}
          height={103}
        />
        <StyledHeader variant="h1">Acesse e gerencie campanhas</StyledHeader>
        <StyledTextCall>
          Faça login com sua conta martins para gerenciar as campanhas
        </StyledTextCall>

        <LoginForm
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          callbackUrl={callbackUrl}
          errorParam={errorParam}
        />
        {/* <ProvidersLogin
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          callbackUrl={callbackUrl}
        /> */}
      </StyledLoginFormBox>
    </StyledGridBox>
  );
}
