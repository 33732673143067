import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DeleteOutlined as DeleteOutlinedIcon } from '@mui/icons-material/';
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import { isArray, uniqueId } from 'lodash';

import { StyledTypeSelectFormRow } from '~/entities';
import { isSegmentEmpty } from '~/entities/Campaign/forms/StepSegmentation/RowSegments';
import type { CampaignSegment } from '~/types';

import { SegmentsSelect } from './SegmentsSelect';

export type TypeSegmentSelectProps = {
  label: string;
  segments: SegmentFromList[];
  setSegments: Dispatch<SetStateAction<SegmentFromList[]>>;
};

type SegmentFromList = {
  id: string;
  segmentType?: { id: string; name: string; key: string };
  segmentSelections?: { id: string; name: string }[];
};

const SEGMENTS = [
  { id: '1', name: 'Tipo de negócio', key: 'tipoNegocio' },
  { id: '2', name: 'Estado', key: 'estado' },
  { id: '3', name: 'Departamento', key: 'departamentos' },
];

// Campanha precisa ser passada para o componente assim como em RowSegments.tsx
const campaignSegments: CampaignSegment = {
  departamentos: [],
  tipoNegocio: [],
  estado: [],
};

// Cabe melhorias para carregamento mais rápido em RowSegments é usado Skeleton outra alternativa seria refatorar o codigo

export function TypeSegmentSelect({
  label,
  segments,
  setSegments,
}: TypeSegmentSelectProps) {
  const [blockedSegments, setBlockedSegments] = useState<string[]>([]);
  // const [segments, setSegments] = useState<SegmentFromList[]>([]);

  function handleAddSegment(index: number) {
    setSegments(prevSegments => {
      return [
        ...prevSegments.slice(0, index + 1),
        { id: uniqueId() },
        ...prevSegments.slice(index + 1),
      ];
    });
  }

  function handleRemoveSegment(index: number) {
    setBlockedSegments(state => {
      return state.filter(item => item !== segments[index].segmentType?.key);
    });
    setSegments(state => {
      return state.filter((_, i) => i !== index);
    });
  }

  useEffect(() => {
    if (!campaignSegments) return;

    if (segments.length === 0 && isSegmentEmpty(campaignSegments)) {
      setSegments([{ id: uniqueId() }]);
    }
    if (!isSegmentEmpty(campaignSegments) && segments.length === 0) {
      const segmentsFromCampaign = Object.keys(campaignSegments)
        .map(key => {
          const isEmpty =
            campaignSegments[key as keyof CampaignSegment].length === 0;
          if (isEmpty) return null;
          return {
            id: uniqueId(),
            segmentType:
              (SEGMENTS.find(
                segment => segment.key === key
              ) as SegmentFromList['segmentType']) ?? null,
            segmentSelections:
              (campaignSegments[
                key as keyof CampaignSegment
              ] as SegmentFromList['segmentSelections']) ?? null,
          };
        })
        .filter(item => item !== null) as SegmentFromList[];

      const newBlockedSegments = segmentsFromCampaign
        .map(item => item.segmentType?.key)
        .filter(item => item !== undefined) as string[];

      setSegments(segmentsFromCampaign);
      setBlockedSegments(newBlockedSegments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignSegments, segments]);
  return segments.map((segmentField, index) => (
    <StyledTypeSelectFormRow key={`segment-row-${segmentField.id}`}>
      <Autocomplete
        sx={{ flexGrow: 1, flexBasis: '30%' }}
        id={`segment-type_${segmentField.id}`}
        options={SEGMENTS}
        value={segmentField.segmentType}
        onChange={(_, newValue) => {
          setSegments(prevSegments => {
            const newSegments = [...prevSegments];
            newSegments[index].segmentType = newValue ?? undefined;
            newSegments[index].segmentSelections = [];
            const newBlockedSegments = newSegments
              .map(item => item.segmentType?.key)
              .filter(item => item !== undefined) as string[];
            setBlockedSegments(newBlockedSegments);
            return newSegments;
          });
        }}
        getOptionDisabled={option => blockedSegments.includes(option.key)}
        getOptionLabel={option => option.name}
        getOptionKey={option => option.id}
        renderInput={params => (
          <TextField {...params} variant="filled" label={label} />
        )}
      />

      {!segmentField.segmentType && (
        <TextField
          label="Selecione uma segmentação"
          disabled
          fullWidth
          variant="filled"
        />
      )}

      {segmentField?.segmentType?.key === 'estado' && (
        // eslint-disable-next-line no-console
        <SegmentsSelect
          id="states"
          label="Estados"
          segment="estado"
          onChange={value => {
            if (!isArray(value)) return;
            setSegments(prevSegments => {
              const newSegments = [...prevSegments];
              newSegments[index].segmentSelections = value;
              return newSegments;
            });
          }}
          value={segmentField.segmentSelections}
        />
      )}

      {segmentField?.segmentType?.key === 'tipoNegocio' && (
        <SegmentsSelect
          id="business-types"
          label="Tipos de Negocio"
          segment="tipoNegocio"
          onChange={value => {
            if (!isArray(value)) return;
            setSegments(prevSegments => {
              const newSegments = [...prevSegments];
              newSegments[index].segmentSelections = value;
              return newSegments;
            });
          }}
          value={segmentField.segmentSelections}
        />
      )}
      {segmentField?.segmentType?.key === 'departamentos' && (
        <SegmentsSelect
          id="departments"
          label="Departamentos"
          segment="departamentos"
          onChange={value => {
            if (!isArray(value)) return;
            setSegments(prevSegments => {
              const newSegments = [...prevSegments];
              newSegments[index].segmentSelections = value;
              return newSegments;
            });
          }}
          value={segmentField.segmentSelections}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
        }}
      >
        {segments.length <= 2 && (
          <Button
            variant="outlined"
            sx={{ borderRadius: 3, height: 50 }}
            onClick={() => handleAddSegment(index)}
          >
            E
          </Button>
        )}
        {segments.length > 1 && (
          <IconButton
            color="primary"
            onClick={() => handleRemoveSegment(index)}
            sx={{ width: 50, height: 50 }}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </StyledTypeSelectFormRow>
  ));
}
